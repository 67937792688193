import React from 'react';
import Layout from '../../components/Layout';

function Index(props) {
    return (
        <div>
            <Layout>
                <section className="h-100 py-20 w-full hero" style={{position: "relative"}}>
                    <div className="" style={{height: "100%", width: "100%"}}>
                        <div className="bg-white  md:mx-0 px-6 lg:px-16 xl:px-12
                        flex items-center justify-center">

                            <div className="h-100 container lg:w-1/3 w-2/3 m-auto my-7 mb-12">

                                <form action="https://cwamerchantservices.transactiongateway.com/merchants/login.php" method="POST">
                                    <input name="FORM_POSTED" type="hidden" value="true"/>
                                    <input name="Action" type="hidden" value="Login"/>
                                    <h1 className="text-xl md:text-2xl font-bold leading-tight mt-12 mb-5">CWA Gateway Login</h1>
                                    <div>
                                        <label className="block text-gray-700">Username</label>
                                        <input type="text" name="username" id="username" placeholder="Enter Username"
                                            className="rounded w-full px-4 py-3 bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                                            autofocus autocomplete required/>
                                    </div>

                                    <div className="mt-4">
                                        <label className="block text-gray-700">Password</label>
                                        <input type="password" name="password" id="password" placeholder="Enter Password" minlength="6" className="rounded w-full px-4 py-3 bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none" required/>
                                    </div>

                                    <input type="submit" value="Log In" className="rounded w-full block cw-bg-lightblue hover:bg-blue-500 text-white font-semibold px-4 py-3 mt-6"/>
                                    <hr className="my-6 border-gray-300 w-full"/>
                                    <p className="mt-8 text-center">How can the CWA Gateway help you run your business more efficiently?<a href="https://cwamerchantservices.com/merchants/cwa-gateway/" className="text-blue-500 hover:text-blue-400 font-semibold"> Learn&nbsp;More</a></p>
                                </form>
                            </div>
                        </div>
                        
                        
                    </div>
                </section>
                {/* <div style={styles.banner_bg} className="flex bottom-0 banner pt-5 pb-7 px-3">
                    <div className="max-width-2xl mx-auto row text-center">
                        <img style={{marginBottom: "-5px", marginTop:"-20px" }}className="mx-auto"
                            src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1643059237/cwa/assets/images/WIN_ISLANDERS_TICKETS50px_usswo6.png" />
                        <p className="pb-5 text-white font-semibold text-lg">Refer a business that could benefit from CardWorks'
                            services for a chance to win Islanders tickets.</p>
                        <a
                            href="https://islanders.merchantreferral.partners/" className="border-2 border-white p-2 text-white font-semibold hover:bg-white hover:text-blue-800">
                            Learn more</a>
                    </div>
                </div> */}
            </Layout>
        </div>
    );
}

export default Index;